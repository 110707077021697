import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { message } from "@/common/Message";
import { KIOSK_CHANNEL_LINKS_QUERY } from "@/graphql/queries/getChannelLinks";

const AddChannelLinkModal = loadable(() =>
  import("@/components/ChannelLinks/AddChannelLinkModal"),
);
const Button = loadable(() => import("@/common/Button"));
const ChannelLinksTable = loadable(() =>
  import("@/components/ChannelLinks/ChannelLinksTable"),
);
const Layout = loadable(() => import("@/common/Layout"));
const Spin = loadable(() => import("@/common/Spin"));

export default function KioskChannelLinks() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [channelLinks, setChannelsLinks] = useState([]);
  const channelLinksQuery = useQuery(KIOSK_CHANNEL_LINKS_QUERY, {
    context: { graph: "restaurants" },
    onCompleted: (data) => {
      if (data?.kioskChannelLinks) {
        setChannelsLinks(data.kioskChannelLinks);
      }
    },
  });

  useEffect(() => {
    if (channelLinksQuery?.error?.message) {
      message.error(channelLinksQuery.error.message);
    }
  }, [channelLinksQuery?.error?.message]);

  return (
    <Layout
      className="kioskChannelLinks"
      pageTitle={t("kioskChannelLinks.header")}
    >
      <div className="flex justify-end">
        <Link to="/kiosks/new">
          <Button type="primary">{t("kioskChannelLinks.actions.add")}</Button>
        </Link>
        <AddChannelLinkModal
          closeModal={() => navigate("/kiosks")}
          channelLinks={channelLinks}
        />
      </div>
      <Spin spinning={channelLinksQuery?.loading}>
        <div className="mt-4">
          <ChannelLinksTable
            channelLinks={channelLinks}
            editLink={(channelLink) => `/kiosks/${channelLink.id}/edit`}
            brandVisible={false}
          />
        </div>
      </Spin>
    </Layout>
  );
}
